import React, { useState } from "react";

const CardNumberInput = ({ onChange, value }) => {
  const [cardNumber, setCardNumber] = useState("");

  const handleChange = (e) => {
    // Basic card number formatting: XXXX-XXXX-XXXX-XXXX
    let formattedNumber = e.target.value.replace(/\D/g, "");
    formattedNumber = formattedNumber.replace(/(.{4})/g, "$1 ").trim();

    setCardNumber(formattedNumber);

    if (onChange) {
      onChange(formattedNumber);
    }
  };

  return (
    <input
      type="text"
      className="rds-text-input"
      placeholder="XXXX XXXX XXXX XXXX"
      name="card"
      aria-describedby=""
      value={cardNumber}
      onChange={handleChange}
      maxLength={19}
    />
  );
};

export default CardNumberInput;
